import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import * as Ant from "antd";
import * as Theme from "../Theme";
import { withPage } from "../PageContainer";
import * as Catalog from "../Contexts/CatalogContext";
import Constants from "../constants";

class PromoZoneList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promoZones: [],
    };
  }

  async componentDidMount() {
    let { type } = this.props;
    // let promoZones = await Catalog.Actions.fetchPromoItems(type);
    // this.setState({ promoZones });
  }

  render() {
    let { extraCss = "", type } = this.props;
    let { promoZones } = this.state;
    let hasLabel = false;
    if (type == "bottom_zone") {
      // big item ui
      hasLabel = true;
    }
    if (hasLabel) {
      return (
        <Wrapper extraCss={extraCss + "overflow: auto;"}>
          <Ant.Row style={{ flexWrap: "nowrap" }}>
            {promoZones
              .sort((a, b) => {
                if (a.priority === null) {
                  return 1;
                } else if (b.priority === null) {
                  return -1;
                }
                return a.priority - b.priority;
              })
              .map((promoZone, idx) => (
                <PromoZoneItem key={idx} promoZone={promoZone} hasLabel />
              ))}
          </Ant.Row>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper extraCss={extraCss}>
          <Ant.Row gutter={16}>
            {promoZones
              .sort((a, b) => {
                if (a.priority === null) {
                  return 1;
                } else if (b.priority === null) {
                  return -1;
                }
                return a.priority - b.priority;
              })
              .slice(0, 3)
              .map(promoZone => (
                <PromoZoneItem promoZone={promoZone} />
              ))}
          </Ant.Row>
        </Wrapper>
      );
    }
  }
}

class PromoZoneItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { promoZone, hasLabel } = this.props;
    return (
      <Ant.Col xs={24} sm={24} md={8} lg={8} xxl={hasLabel ? 4 : 8}>
        <a
          target={hasLabel ? "_self" : "_blank"}
          href={hasLabel ? `/products?zone=${promoZone.id}` : promoZone.link}
        >
          <Image
            image={`${Constants.mediaHost}${promoZone.image}`}
            height={hasLabel ? "300px" : "260px"}
          >
            {hasLabel && (
              <Label>
                <Ant.Button
                  className="hover-reverse-btn"
                  type="primary"
                  style={{
                    flexGrow: 0,
                    border: 0,
                  }}
                >
                  {promoZone.label}
                </Ant.Button>
              </Label>
            )}
          </Image>
        </a>
      </Ant.Col>
    );
  }
}

const Wrapper = styled.div`
  ${props => props.extraCss}
`;

const Image = styled.div`
  position: relative;
  cursor: pointer;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e0e0e0;
  height: ${props => props.height || "200px"};
`;

const Label = styled.div`
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);

  border-radius: 3px;
  /* display: flex;
  justify-content: center; */

  & > p {
    color: ${Theme.colors.textReverse};
    margin: 0px;
  }

  & .hover-reverse-btn {
    background-color: ${Theme.colors.main};
    color: ${Theme.colors.textReverse};
    &:hover {
      color: ${Theme.colors.main};
      background-color: ${Theme.colors.textReverse};
    }
  }
`;

export default PromoZoneList;
