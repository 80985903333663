import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { Link } from "gatsby";

const themeColor = "darkgray";

function ArticleItem({ article, type = "portrait", extraStyle = {} }) {
  const Wrapper = type === "portrait" ? WrapperPortrait : WrapperLandscape;

  return (
    <Wrapper style={extraStyle} article={article}>
      <figure />

      <section>
        <h3>{article.title || "No title"}</h3>
        <p>{article.abstract || "No abstract"}</p>

        <div className="misc">
          <div className="date">
            {article.created && article.created.replace("T", " ").slice(0, 16)}
          </div>
          <div style={{ flex: 1 }} />
          <Ant.Button type="link">
            <Link to={`/article?id=${article.id}`}>Read More</Link>
          </Ant.Button>
        </div>
      </section>
    </Wrapper>
  );
}

const WrapperPortrait = styled.div`
  border: 1px solid #e0e0e0;
  width: 250px;
  height: 400px;
  position: relative;

  & > figure {
    width: 100%;
    height: 50%;
    margin: 0;
    background-image: url(${props => props.article.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #e0e0e0;
  }

  & > section {
    height: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;

    & > h3 {
      padding: 0;
      margin: 0;
      color: ${themeColor};
    }

    & > p {
      flex: 1;
    }

    & > .misc {
      display: flex;
      align-items: center;
      border-top: 1px solid #e0e0e0;
      padding-top: 8px;

      .date {
        font-size: 10px;
      }
    }
  }
`;

const WrapperLandscape = styled.div`
  border: 1px solid #e0e0e0;
  padding: 10px;
  display: flex;
  align-items: flex-start;

  & > figure {
    width: 120px;
    height: 90px;
    flex-basis: 120px;
    flex-grow: 0;
    flex-shrink: 0;
    background-image: url(${props => props.article.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #e0e0e0;
  }

  & > section {
    margin: 0px 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;

    & > h3 {
      padding: 0;
      margin: 0;
      color: ${themeColor};
    }

    & > p {
      flex: 1;
    }

    & > .misc {
      display: flex;
      align-items: center;
      border-top: 1px solid #e0e0e0;
      padding-top: 8px;

      .date {
        font-size: 10px;
      }
    }
  }
`;

export default ArticleItem;
