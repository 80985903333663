import React from "react";
import * as Gatsby from "gatsby";
import styled from "styled-components";
import moment from "moment";
import * as Ant from "antd";
import * as Theme from "../Theme";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import ApiArticle from "../ApiArticle";
import Constants from "../constants";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Catalog from "../Contexts/CatalogContext";
import ArticleItem from "../Components/ArticleItem";
import Carousel from "../Components/HomeBannerCarousel";
import PromoZoneList from "../Components/PromoZoneList";
import ProductItem from "../Components/ProductItem";
import { StaticImage } from "../Components/StaticImage";
const slugify = require("uslug");

const MAX_WIDTH = 1024;
// function Category({ type, products }) {
//   return (
//     <div style={{ marginBottom: 20 }}>
//       <h2>{type}</h2>
//       {products.map(product => (
//         <Gatsby.Link key={product.name} to={`/product/${product.name}`}>
//           <div
//             style={{
//               border: "1px solid grey",
//               padding: 20,
//               backgroundColor: "white",
//               margin: 10,
//             }}
//           >
//             <h3>{product.name}</h3>
//           </div>
//         </Gatsby.Link>
//       ))}

//       {products.length === 0 && <div>No products</div>}
//     </div>
//   );
// }

const fake_banners = [
  {
    image: "../../images/banner1.png",
  },
  // {
  //   image: "../../images/banner2.jpg",
  // },
];

const intro_items = [
  {
    icon: "../../images/team.png",
    title: "團隊",
    subtitle:
      "創辦人兼執行長何宗翰先生，身為台灣唯一榮獲多項國際綠建築協會認證的成員，易境團隊在他的帶領下，集結了業界頂尖建築、物理、機械等領域的專業人士，以領先的綠建築科學與精密技術，為顧客提供客製化的永續設計方案，以及國際級的綠建築顧問服務。",
  },
  {
    icon: "../../images/service.png",
    title: "服務",
    subtitle:
      "易境永續設計顧問有限公司提供企業客戶LEED與WELL綠建築認證顧問服務，以及其他永續建築設計的策略分析與諮詢，包含建築材料的選擇、產品規格、照明、採光、自然通風，以及空間格局規劃等。                                             ",
  },
  {
    icon: "../../images/education.png",
    title: "教育",
    subtitle:
      "推廣綠色與健康建築設計的理念，是創始人兼執行長何宗翰先生創立公司的初衷之一。身為台灣唯一榮獲多項國際綠建築認證的何宗翰先生，定期開設綠建築的專業課程。每年我們會定期舉辦工作坊(Workshop)，邀請業界的專業人士與綠建築同好，共同交流綠建築的新知。",
  },
];

const social_icons = [
  {
    image: "social_ig.png",
    link: "https://www.instagram.com/li.xiang.print/",
  },
  {
    image: "social_line.png",
    link: "https://line.me/R/ti/p/%40754pymsx",
  },
  {
    image: "social_fb.png",
    link: "https://www.facebook.com/lixiangprint/",
  },
];
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this._apiArticle = ApiArticle({ apiHost: Constants.revBlogServiceApi });
    this.state = {
      banners: fake_banners,
      products: [],
      articles: [],
    };
  }

  async componentDidMount() {
    try {
      this._fetchArticles();
      //let banners = await Catalog.Actions.fetchPromoItems("banner");
      //this.setState({ banners });
      let products = await Catalog.Actions.fetchProducts({
        category: "最新",
        sort: "",
      });
      this.setState({ products });
    } catch (ex) {
      console.warn(ex);
    }
  }

  render() {
    let { navActions } = this.props;
    const { banners, products, articles } = this.state;
    const itemExtraCss = "";
    // "width: calc(25% - 10px);@media screen and (max-width:600px) {width: calc(50% - 10px);}";
    return (
      <Wrapper>
        <div className="center-content">
          <Carousel imagesArray={banners} />
          <div className={"sub-promote-banner-wrapper"}>
            <PromoZoneList type={"top_zone"} />
          </div>
          {/* <Slogan>
            肯定生命 ｜ 珍惜環境 ｜ 促進社會邁向心靈普遍開啟與提昇的新時代
          </Slogan> */}
          <IntroItems>
            {intro_items.map((item, idx) => (
              <IntroItem index={idx}>
                <img src={item.icon} height="auto" width="60" />
                <div className="col">
                  <p className="title" data-delighter>
                    {item.title}
                  </p>
                  <p className="subtitle" data-delighter>
                    {item.subtitle}
                  </p>
                </div>
              </IntroItem>
            ))}
          </IntroItems>
          {/* <div className="row-section">
            <p className="title">熱門商品</p>
            <div className="products-wrapper">
              {products
                .filter(p => p.tag == "HOT")
                .slice(0, 8)
                .map((p, idx) => {
                  return (
                    <ProductItem
                      key={idx}
                      product={p}
                      onClick={() => {
                        navActions.push(`/product/${slugify(p.name)}`);
                      }}
                      extraCss={itemExtraCss}
                    />
                  );
                })}

              {[0, 1, 2].map(e => (
                <ProductItem key={e} product={null} extraCss={itemExtraCss} />
              ))}
            </div>
          </div> */}
        </div>

        {/* <div style={{ background: "#ececec" }}>
          <PromoZoneList type={"bottom_zone"} extraCss={"margin-top: 15px;"} />
        </div> */}

        <div className="center-content">
          <div className="row-section">
            <p className="title">早鳥優惠</p>
            <div className="products-wrapper">
              {products
                .filter(p => p.early_bird)
                .sort((a, b) => moment(b.created) - moment(a.created))
                .map((p, idx) => {
                  return (
                    <ProductItem
                      key={idx}
                      product={p}
                      onClick={() => {
                        navActions.push(`/product?id=${p.id}`);
                      }}
                      extraCss={itemExtraCss}
                    />
                  );
                })}

              {/* compensate blank item for ui: justfy-content: space-between */}
              {[0, 1, 2].map(e => (
                <ProductItem key={e} product={null} extraCss={itemExtraCss} />
              ))}
            </div>
          </div>
        </div>

        <div className="center-content">
          <div className="row-section">
            <p className="title">熱門課程</p>
            <div className="products-wrapper">
              {products
                .sort((a, b) => moment(b.created) - moment(a.created))
                .map((p, idx) => {
                  return (
                    <ProductItem
                      key={idx}
                      product={p}
                      onClick={() => {
                        navActions.push(`/product?id=${p.id}`);
                      }}
                      extraCss={itemExtraCss}
                    />
                  );
                })}

              {/* compensate blank item for ui: justfy-content: space-between */}
              {[0, 1, 2].map(e => (
                <ProductItem key={e} product={null} extraCss={itemExtraCss} />
              ))}
            </div>
            <Ant.Button
              className="hover-reverse-btn"
              type="primary"
              onClick={() => navActions.push("/products")}
              style={{
                flexGrow: 0,
                border: 0,
              }}
            >
              全部課程
            </Ant.Button>
          </div>
        </div>
        {/* <IntroWrapper>
          <p data-delighter>介紹介紹1</p>
          <p data-delighter>介紹介紹2</p>
          <p data-delighter>介紹介紹3</p>
          <p data-delighter>介紹介紹4</p>
         
        </IntroWrapper> */}
        {/* <div className="center-content">
          <div className="row-section">
            <p className="title">理想聚焦</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {articles.slice(0, 3).map(record => (
                <div key={record.id} className="article-item-wrapper">
                  <ArticleItem
                    article={record}
                    extraStyle={{
                      marginTop: 10,
                      marginBottom: 10,
                      width: "100%",
                    }}
                    type="portrait"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="row-section">
            <p className="title">社群連結</p>
            <div className="social-icons-wrapper">
              {social_icons.map((icon, idx) => (
                <a
                  className={"social-icon"}
                  key={idx}
                  href={`${icon.link}`}
                  target="_blank"
                >
                  <StaticImage key={idx} name={`${icon.image}`} />
                </a>
              ))}
            </div>
          </div>
        </div> */}
      </Wrapper>
    );
  }

  _fetchArticles = async () => {
    try {
      const resp = await this._apiArticle.findArticles({
        find: {
          project: "sensationsprint",
          label: {
            $not: {
              $regex: "FAQ",
            },
          },
        },
        sort: "-created",
      });

      this.setState({ articles: resp });
    } catch (ex) {}
  };
}

const Wrapper = styled.div`
  background-color: #fff;

  .center-content {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 20px auto;
    padding: 0px 20px;

    & > .sub-promote-banner-wrapper {
      overflow: hidden;
      padding-top: 15px;
      @media screen and (max-width: 600px) {
        padding: 20px;
      }
    }

    & .row-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 25px;

      & > .title {
        text-align: center;
        font-size: ${Theme.titleFontSize};
        color: ${Theme.colors.main};
        padding: 7px 50px;
        border-bottom: 1px solid ${Theme.colors.main};
      }

      & .social-icons-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        & > .social-icon {
          width: calc(33% - 20px);
        }

        & > .social-icon:nth-child(2) {
          margin-left: 10px;
          margin-right: 10px;
        }

        @media screen and (max-width: 600px) {
          & > .social-icon {
            width: 100%;
          }
          & > .social-icon:nth-child(2) {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }

      & .article-item-wrapper {
        width: calc(33.33% - 10px);

        @media screen and (max-width: 600px) {
          width: 100%;
          margin: 5px 0px;
        }
      }

      & > .products-wrapper {
        align-self: stretch;
        /* min-height: 640px; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @media screen and (max-width: 600px) {
        padding: 0px 15px;
        & > .products-wrapper {
          justify-content: center;
        }
      }
    }
  }

  & .col {
    display: flex;
    flex-direction: column;
    min-height: 230px;

    & > .title {
      letter-spacing: 2px;
      color: #5a5a5a;
      margin: 20px 0px;
    }

    & > .title.delighter,
    & > .subtitle.delighter {
      transition: all 0.3s ease-out;
      transform: translateY(50%);
      opacity: 0.5;
    }

    & > .title.delighter.started,
    & > .subtitle.delighter.started {
      transform: none;
      opacity: 1;
    }

    & > .subtitle {
      color: #5a5a5a;
      font-size: 10px;
      min-height: 150px;
      margin-top: 10px;

      @media (max-width: 600px) {
        max-width: 300px;
      }
    }
  }
`;

const IntroItems = styled.div`
  margin-top: 20px;
  justify-content: space-around;
  /* overflow: hidden; */
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const IntroItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 25%;
  padding: 10px 0px;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-basis: 50%;
    /* border-left: ${props =>
      props.index % 2 === 0 ? "none" : "1px solid #bdbdbd"};
    border-bottom: ${props =>
      props.index < 2 ? "1px solid #bdbdbd" : "none"}; */
  }

  & .col {
    display: flex;
    flex-direction: column;
    flex-basis: 110px;

    & > .subtitle {
      font-weight: 300;
      color: #444444;
      font-size: 12px;
      letter-spacing: 1px;
    }

    & p {
      margin-bottom: 0px;
    }

    & > .title {
      font-weight: 300;
      font-size: ${Theme.titleFontSize};
      text-align: center;
      letter-spacing: 2px;
      color: #444444;
      margin-bottom: 5px;
    }
  }
  ${props => props.css}
`;

const IntroWrapper = styled.div`
  background-color: #eeeeef;
  height: 230px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: calc((100% - ${MAX_WIDTH}px) / 2);
  padding-right: calc((100% - ${MAX_WIDTH}px) / 2);

  @media screen and (max-width: 1024px) {
    padding: 0px 20px;
  }

  & > p.delighter {
    transition: all 0.3s ease-out;
    transform: translateY(50%);
    opacity: 0.2;
  }

  & > p.delighter.started {
    transform: none;
    opacity: 1;
  }

  & > p {
    color: #9a9a9a;
    letter-spacing: 2px;
    line-height: 20px;
  }

  & .hover-reverse-btn {
    align-self: flex-end;
    background-color: ${Theme.colors.main};
    color: ${Theme.colors.textReverse};
    &:hover {
      color: ${Theme.colors.main};
      background-color: ${Theme.colors.textReverse};
    }
  }
`;

const Slogan = styled.div`
  width: 100%;
  height: 140px;
  background-image: url("/images/footer_bg.jpg");
  background-position: center bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  word-break: keep-all;

  @media screen and (max-width: 1024px) {
    padding: 0px 20px;
    font-size: 14px;
  }
`;

export default withPage(
  Catalog.withConsumer(
    connect(
      (state, ownProps) => ({
        profile: Selectors.getLoginUser(state),
      }),
      ActionCreator
    )(LandingPage)
  )
);
