const ArticleApiFactory = ({ apiHost }) => {
  if (!apiHost) {
    throw "MISSING REQUIRED PARAMS: Article Api Facotry needs apiHost";
  }

  return {
    createArticle: async token => {
      const resp = await fetch(`${apiHost}/article?token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },

    getArticles: async ({ project, label }) => {
      let url = `${apiHost}/articles?project=${project}`;

      if (label) {
        url += `&label=${label}`;
      }

      const resp = await fetch(url);

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }
      return await resp.json();
    },

    getArticleById: async id => {
      const resp = await fetch(`${apiHost}/article/${id}`);

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },

    updateArticle: async (id, data, token) => {
      const resp = await fetch(`${apiHost}/article/${id}?token=${token}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },

    deleteArticle: async (id, token) => {
      const resp = await fetch(`${apiHost}/article/${id}?token=${token}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return;
    },

    findArticles: async params => {
      const resp = await fetch(`${apiHost}/articles/find`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (resp.status.toString()[0] !== "2") {
        throw new Error(resp);
      }

      return await resp.json();
    },
  };
};

export default ArticleApiFactory;
